import React from "react"
import PropTypes from "prop-types"
import { ThemeContext } from "../layouts";
import Article from "../components/Article/";
import Headline from "../components/Article/Headline";
// Utilities
import kebabCase from "lodash/kebabCase"

// Components
import Helmet from "react-helmet"
import { Link, graphql } from "gatsby"

const DietsPage = ({
  data: {
    allMarkdownRemark: { group },
    site: {
      siteMetadata: { title },
    },
  },
}) => (
  <React.Fragment>
    <ThemeContext.Consumer>
      {theme => (
        <Article theme={theme}>
          <header>
            <Headline title="Recipes by diet" theme={theme} />
          </header>
          <ul>
        {group.map(diet => (
          <li key={diet.fieldValue}>
            <Link to={`/diets/${kebabCase(diet.fieldValue)}/`}>
              {diet.fieldValue} [{diet.totalCount}]
            </Link>
          </li>
        ))}
      </ul>
      </Article>
         )}
       </ThemeContext.Consumer>
       </React.Fragment>
)

DietsPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      group: PropTypes.arrayOf(
        PropTypes.shape({
          fieldValue: PropTypes.string.isRequired,
          totalCount: PropTypes.number.isRequired,
        }).isRequired
      ),
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        title: PropTypes.string.isRequired,
      }),
    }),
  }),
}

export default DietsPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      limit: 2000
    ) {
      group(field: frontmatter___diet) {
        fieldValue
        totalCount
      }
    }
  }`
